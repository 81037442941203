define("cb-ui/routes/app", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "cb-ui/config/environment", "ember-local-storage", "ember-ajax"], function (_exports, _authenticatedRouteMixin, _environment, _emberLocalStorage, _emberAjax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    notify: Ember.inject.service(),
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    afterModel: function afterModel() {
      var _this = this;

      this.get('sessionContext.currentUser').then(function (user) {
        var userLanguage = user.get('language');

        var locale = _this.get('intl.locale');

        if (userLanguage && locale && userLanguage !== locale[0]) {
          _this.get('intl').setLocale(userLanguage);
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.get('sessionContext.currentUser').then(function (user) {
        controller.set('user', user);
        controller.set('userPhone', user.get('mobilePhone') ? user.get('mobilePhone') : user.get('phone') ? user.get('phone') : null);
      });
    },
    actions: {
      changeUserLanguage: function changeUserLanguage(language) {
        var _this2 = this;

        var localStorage = this.get('localStorage');
        this.get('sessionContext.currentUser').then(function (user) {
          var oldLanguage = user.get('language');

          if (oldLanguage !== language) {
            user.changeLanguage({
              'language': language
            }).then(function () {
              user.set('language', language);

              _this2.get('intl').setLocale(language);

              localStorage.set('language', language);
            }).catch(function () {
              _this2.get('notify').error(_this2.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
            });
          }
        });
      },
      contactTCBAgent: function contactTCBAgent() {
        this.controller.set('showAgentQuestionModal', true);
      },
      newAgentQuestion: function newAgentQuestion() {
        var _this3 = this;

        if (this.controller.get('question')) {
          this.store.createRecord('agent-question', {
            question: this.controller.get('question'),
            userPhone: this.controller.get('userPhone')
          }).save().then(function () {
            _this3.get('notify').success(_this3.get('intl').t('notifyMessages.requestWasSent').toString());

            _this3.controller.set("showAgentQuestionModal", false);

            _this3.controller.set("question", null);

            _this3.controller.set("userPhone", null);
          }).catch(function () {
            _this3.get('notify').error(_this3.get('intl').t('notifyMessages.errorOccurredTryAgain'));
          });
        } else {
          this.get('notify').error(this.get('intl').t('notifyMessages.fillInQuestion').toString());
        }
      },
      closeAgentQuestionModal: function closeAgentQuestionModal() {
        this.controller.set('userPhone', null);
        this.controller.set('showAgentQuestionModal', false);
        this.controller.set('question', null);
      }
    }
  });

  _exports.default = _default;
});