define("cb-ui/authorizers/token", ["exports", "ember-simple-auth/authorizers/base", "cb-ui/utils/get-target-domain"], function (_exports, _base, _getTargetDomain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function jwtPayload(token) {
    if (token) {
      return JSON.parse(atob(token.split('.')[1]));
    }

    return null;
  }

  function refreshCall(refreshToken) {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", '/api/jwt/refresh/', false);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify({
      refresh: refreshToken
    }));

    if (xhr.status !== 200) {
      window.localStorage.removeItem('ember_simple_auth:session');
      window.localStorage.removeItem('jwtRefreshToken');
      window.location = "".concat((0, _getTargetDomain.default)(), "/login?logoutBefore=true");
    } else {
      return JSON.parse(xhr.responseText);
    }
  }

  var _default = _base.default.extend({
    session: Ember.inject.service(),
    authorize: function authorize(_ref, block) {
      var token = _ref.token;
      token = JSON.parse(window.localStorage.getItem('ember_simple_auth:session')).authenticated.token;
      var expire = jwtPayload(token).exp;
      var now = new Date().getTime() / 1000;

      if (expire - now < 30) {
        var refreshToken = window.localStorage.getItem('jwtRefreshToken');

        if (refreshToken) {
          var _token = refreshCall(refreshToken);

          if (_token.access) {
            var authSession = JSON.parse(window.localStorage.getItem('ember_simple_auth:session'));
            authSession.authenticated.token = _token.access;
            window.localStorage.setItem('ember_simple_auth:session', JSON.stringify(authSession));
            block('Authorization', "Bearer ".concat(JSON.parse(window.localStorage.getItem('ember_simple_auth:session')).authenticated.token));
          } else {
            window.localStorage.removeItem('ember_simple_auth:session');
          }
        } else {
          window.localStorage.removeItem('ember_simple_auth:session');
        }
      } else {
        block('Authorization', "Bearer ".concat(JSON.parse(window.localStorage.getItem('ember_simple_auth:session')).authenticated.token));
      }
    }
  });

  _exports.default = _default;
});