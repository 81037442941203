define("cb-ui/authenticators/token", ["exports", "ember-simple-auth/authenticators/base", "cb-ui/config/environment", "cb-ui/utils/get-target-domain"], function (_exports, _base, _environment, _getTargetDomain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function jwtPayload(token) {
    if (token) {
      return JSON.parse(atob(token.split('.')[1]));
    }

    return null;
  }

  function refreshAndBlacklistCall(refreshToken) {
    var xhr = new XMLHttpRequest();
    xhr.open("POST", '/api/jwt/refresh-and-blacklist/', false);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify({
      refresh: refreshToken
    }));

    if (xhr.status !== 200) {
      window.localStorage.removeItem('ember_simple_auth:session');
      window.localStorage.removeItem('jwtRefreshToken');
      window.location = "".concat((0, _getTargetDomain.default)(), "/login?logoutBefore=true");
    } else {
      return JSON.parse(xhr.responseText);
    }
  }

  var _default = _base.default.extend({
    serverTokenEndpoint: _environment.default.backendAPI + _environment.default['simple-token'].serverTokenEndpoint,
    verifyServerTokenEndpoint: _environment.default.backendAPI + _environment.default['simple-token'].verifyServerTokenEndpoint,
    tokenAttributeName: 'token',
    identificationAttributeName: _environment.default['simple-token'].identificationAttributeName,
    notify: Ember.inject.service(),
    authenticate: function authenticate(credentials) {
      var _this = this;

      credentials.username = credentials.email;

      if (credentials.sessionToken) {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          var decoded = jwtPayload(credentials.sessionToken);
          var currentToken = JSON.parse(window.localStorage.getItem('ember_simple_auth:session')).authenticated.token;
          var currentDecoded = {
            user_id: null
          };

          if (currentToken) {
            currentDecoded = jwtPayload(currentToken);
          }

          var refreshed = {
            access: null
          };

          if (currentDecoded.user_id !== decoded.user_id) {
            if (decoded.token_type === 'refresh') {
              refreshed = refreshAndBlacklistCall(credentials.sessionToken);
            }

            if (refreshed.access || decoded.token_type === 'access') {
              if (decoded.token_type === 'refresh') {
                window.localStorage.setItem('jwtRefreshToken', refreshed.refresh);
              }

              credentials.token = credentials.sessionToken;
              Ember.$.ajax({
                url: _this.verifyServerTokenEndpoint,
                type: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                data: JSON.stringify(credentials),
                dataType: 'json'
              }).then(function (data, statusText, response) {
                if (response.status !== 200) {
                  reject(response);
                  window.location = "".concat((0, _getTargetDomain.default)(), "/login?logoutBefore=true");
                }

                resolve(response);
              }, function (response) {
                reject(response);
                window.location = "".concat((0, _getTargetDomain.default)(), "/login?logoutBefore=true");
              });
            } else {
              reject();
              window.location = "".concat((0, _getTargetDomain.default)(), "/login?logoutBefore=true");
            }
          } else {
            credentials.token = credentials.sessionToken;
            Ember.$.ajax({
              url: _this.verifyServerTokenEndpoint,
              type: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              data: JSON.stringify(credentials),
              dataType: 'json'
            }).then(function (data, statusText, response) {
              if (response.status !== 200) {
                reject(response);
                window.location = "".concat((0, _getTargetDomain.default)(), "/login?logoutBefore=true");
              }

              resolve(response);
            }, function (response) {
              reject(response);
              window.location = "".concat((0, _getTargetDomain.default)(), "/login?logoutBefore=true");
            });
          }
        });
      } else {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.$.ajax({
            url: _this.serverTokenEndpoint,
            type: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            data: JSON.stringify(credentials),
            dataType: 'json'
          }).then(function (data, statusText, response) {
            if (response.status >= 400) {
              reject(response);
            }

            if (response.status > 200 && response.status < 300) {
              _this.get('notify').success(_this.get('intl').t('notifyMessages.signupSuccessful').toString());

              setTimeout(function () {
                window.location.assign("".concat(_environment.default.CANONICAL_DOMAIN_NEXT, "/login?logoutBefore=true"));
              }, 2000);
              return;
            }

            if (data.otp_required_token) {
              _this.get('notify').success(_this.get('intl').t('notifyMessages.signupSuccessfulOtp').toString());

              setTimeout(function () {
                window.location.assign("".concat(_environment.default.CANONICAL_DOMAIN_NEXT, "/login/otp-validation?jwtOtpToken=").concat(data.otp_required_token));
              }, 2000);
              return;
            }

            window.localStorage.setItem('jwtRefreshToken', data.refresh);
            var decodedAccess = jwtPayload(data.access);
            data.user_id = decodedAccess.user_id;
            data.token = data.access;
            data.email = decodedAccess.email;
            resolve(response);
          }, function (response) {
            reject(response);
          });
        });
      }
    },
    restore: function restore(data) {
      var _this$getProperties = this.getProperties('tokenAttributeName', 'identificationAttributeName'),
          tokenAttributeName = _this$getProperties.tokenAttributeName,
          identificationAttributeName = _this$getProperties.identificationAttributeName;

      var tokenAttribute = Ember.get(data, tokenAttributeName);
      var identificationAttribute = Ember.get(data, identificationAttributeName);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(tokenAttribute) && !Ember.isEmpty(identificationAttribute)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    invalidate: function invalidate() {
      return Ember.RSVP.resolve();
    }
  });

  _exports.default = _default;
});