define("cb-ui/controllers/axa-request-form", ["exports", "ember-changeset", "ember-changeset-validations", "cb-ui/validations/axaQuote", "ember-intl", "moment", "ember-local-storage"], function (_exports, _emberChangeset, _emberChangesetValidations, _axaQuote, _emberIntl, _moment, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable no-console */
  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    queryParams: ['c'],
    c: 'axa',
    localStorage: (0, _emberLocalStorage.storageFor)('local-storage'),
    appLanguage: Ember.computed.alias('localStorage.language'),
    isSendingRequest: false,
    budgetApprovalStatuses: ['pending', 'approved'],
    setDefaultCountry: function setDefaultCountry() {
      var germany = this.get('countries').filter(function (x) {
        return x.id === "65";
      })[0];
      this.get('quoteChangeset').set('mainLocation', germany);
    },
    setDefaultLanguage: function setDefaultLanguage() {
      var german = this.get('languages').filter(function (x) {
        return x.id === "2";
      })[0];
      this.get('quoteChangeset').set('mainLanguage', german);
      this.get('quoteChangeset').set('proposalLanguage', german);
    },
    setDefaultProjectLanguage: function setDefaultProjectLanguage() {
      this.get('quoteChangeset').set('projectLanguage', 'de');
    },
    setDefaultIndustryStandard: function setDefaultIndustryStandard() {
      var insurance = this.get('industryStandards').filter(function (x) {
        return x.id === "35";
      })[0];
      this.get('quoteChangeset').set('industryStandard', insurance);
    },
    setDefaultClientProfile: function setDefaultClientProfile() {
      var clientProfile = "Der AXA Konzern zählt mit Beitragseinnahmen von 12 Mrd. Euro (2022) und 8.028 Mitarbeitenden zu den größten Erstversicherern in Deutschland. Die AXA Deutschland ist Teil der AXA Gruppe, einem der weltweit führenden Versicherungsunternehmen und Vermögensmanager mit Tätigkeitsschwerpunkten in Europa, Nordamerika und dem asiatisch-pazifischen Raum.";
      this.get('quoteChangeset').set('clientIntroduction', clientProfile);
    },
    setDefaultBudgetApprovalStatus: function setDefaultBudgetApprovalStatus() {
      this.get('quoteChangeset').set('budgetApprovalStatus', 'pending');
    },
    setDefaultBudgetEstimateCurrency: function setDefaultBudgetEstimateCurrency() {
      this.get('quoteChangeset').set('budgetEstimateCurrency', 'EUR');
    },
    setupChangeset: function setupChangeset() {
      this.get('quote').set('companyAlias', this.get('c'));
      Ember.set(this, 'quoteChangeset', new _emberChangeset.default(this.get('quote'), (0, _emberChangesetValidations.default)(_axaQuote.default), _axaQuote.default));
    },
    componentHTML: null,
    initComponentHTML: Ember.on('init', function () {
      this.set('componentHTML', this.get('languageHTML'));
    }),
    languageHTML: (0, _emberIntl.translationMacro)('app.rfp.projectDetails.info.mainLanguage'),
    actions: {
      handleFormErrors: function handleFormErrors() {
        Ember.run.next(function () {
          if (Ember.$('.has-error') && Ember.$('.has-error').length) {
            Ember.$("html, body").animate({
              scrollTop: Ember.$('.has-error').offset().top - 100
            });
          }
        });
      },
      submitAction: function submitAction(changeset) {
        var _this = this;

        if (this.get('isSendingRequest')) {
          return;
        }

        this.set('isSendingRequest', true);
        var quoteProjectGoals = this.get('localQuoteProjectGoals');
        var quoteServiceProviders = this.get('localServiceProviders');

        if (quoteProjectGoals.length === 0) {
          Ember.run.next(function () {
            if (Ember.$('.has-error') && Ember.$('.has-error').length) {
              Ember.$('html, body').animate({
                scrollTop: Ember.$('.has-error').offset().top - 100
              });
            }
          });
          return;
        }

        var oldStartDate = changeset.get('startDate');
        var oldEndDate = changeset.get('endDate');
        changeset.set('quoteProjectGoals', quoteProjectGoals);
        changeset.set('quoteServiceProviders', quoteServiceProviders);

        if (changeset.get('projectName') === null) {
          changeset.set('projectName', 'Untitled Project');
        }

        var quoteRelatedPromises = [];
        quoteProjectGoals.forEach(function (item) {
          quoteRelatedPromises.push(item.save({
            adapterOptions: {
              validate: true
            }
          }));
        });
        quoteServiceProviders.forEach(function (item) {
          quoteRelatedPromises.push(item.save({
            adapterOptions: {
              validate: true
            }
          }));
        });
        Ember.RSVP.all(quoteRelatedPromises).then(function () {
          changeset.set('startDate', (0, _moment.default)(_moment.default.utc(changeset.get('startDate')).format()).toDate());
          changeset.set('endDate', (0, _moment.default)(_moment.default.utc(changeset.get('endDate')).format()).toDate());
          changeset.save().then(function () {
            _this.transitionToRoute('axa-request-success');
          }).catch(function (err) {
            changeset.set('startDate', oldStartDate);
            changeset.set('endDate', oldEndDate);
            err.errors.forEach(function (error) {
              if (error.status === "400") {
                _this.get('notify').error(error.detail);
              }
            });

            _this.set('isSendingRequest', false);
          });
          changeset.set('startDate', oldStartDate);
          changeset.set('endDate', oldEndDate);
        }).catch(function (err) {
          Ember.run.next(function () {
            if (Ember.$('.has-error') && Ember.$('.has-error').length) {
              Ember.$("html, body").animate({
                scrollTop: Ember.$('.has-error').offset().top - 100
              });
            }
          });
          err.errors.forEach(function (error) {
            if (error.status === 400) {
              _this.get('notify').error(error.detail);
            }
          });

          _this.set('isSendingRequest', false);
        });
      },
      addQuoteProjectGoal: function addQuoteProjectGoal() {
        var quoteProjectGoal = this.get('store').createRecord('quote-project-goal', {
          quote: null,
          projectGoal: '',
          description: '',
          name: ''
        });
        var localQuoteProjectGoals = this.get('localQuoteProjectGoals');
        localQuoteProjectGoals.addObject(quoteProjectGoal);
      },
      deleteQuoteProjectGoal: function deleteQuoteProjectGoal(quoteProjectGoal) {
        var localQuoteProjectGoals = this.get('localQuoteProjectGoals');

        if (localQuoteProjectGoals.includes(quoteProjectGoal)) {
          localQuoteProjectGoals.removeObject(quoteProjectGoal);
        }
      },
      addQuoteServiceProvider: function addQuoteServiceProvider() {
        var quoteServiceProvider = this.get('store').createRecord('quote-service-provider', {
          quote: null,
          providerName: '',
          contactPersonName: '',
          email: '',
          phoneNumber: ''
        });
        var localServiceProviders = this.get('localServiceProviders');
        localServiceProviders.addObject(quoteServiceProvider);
      },
      deleteQuoteServiceProvider: function deleteQuoteServiceProvider(quoteServiceProvider) {
        var localServiceProviders = this.get('localServiceProviders');

        if (localServiceProviders.includes(quoteServiceProvider)) {
          localServiceProviders.removeObject(quoteServiceProvider);
        }
      }
    }
  });

  _exports.default = _default;
});