define("cb-ui/models/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    companyName: _emberData.default.attr('string'),
    companyNameAlias: _emberData.default.attr('string'),
    published: _emberData.default.attr('boolean'),
    isConsultant: _emberData.default.attr('boolean'),
    isClient: _emberData.default.attr('boolean'),
    extraConsultant: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    generalInfo: _emberData.default.belongsTo('general-info', {
      async: true,
      inverse: 'company'
    }),
    countries: _emberData.default.hasMany('country', {
      async: true
    }),
    industries: _emberData.default.hasMany('industry-standard', {
      async: true
    }),
    services: _emberData.default.hasMany('service-standard', {
      async: true
    }),
    addresses: _emberData.default.hasMany('address', {
      async: true,
      inverse: 'company'
    }),
    individuals: _emberData.default.hasMany('individual', {
      async: true
    }),
    companyUsers: _emberData.default.hasMany('user', {
      async: true,
      inverse: 'company'
    }),
    logo: _emberData.default.attr('string'),
    consultantType: _emberData.default.belongsTo('consultant-type', {
      async: true
    }),
    studies: _emberData.default.hasMany('study', {
      async: true // inverse: 'companies',

    }),
    templates: _emberData.default.hasMany('template', {
      async: true,
      inverse: 'company'
    }),
    globalAddress: Ember.computed('addresses', function () {
      return _emberData.default.PromiseObject.create({
        promise: this.get('addresses').then(function (addresses) {
          var globalAddresses = addresses.filterBy('globalHeadOffice', true);

          if (globalAddresses.get('length')) {
            return globalAddresses.get('firstObject');
          } else {
            return addresses.get('firstObject');
          }
        })
      });
    }),
    industriesNames: Ember.computed('industries', function () {
      return _emberData.default.PromiseObject.create({
        promise: this.get('industries').then(function (industries) {
          return {
            names: industries.mapBy('industryEn').join()
          };
        })
      });
    }),
    specializationsNames: Ember.computed('services', function () {
      return _emberData.default.PromiseObject.create({
        promise: this.get('services').then(function (services) {
          return {
            names: services.mapBy('serviceEn').join()
          };
        })
      });
    }),
    companySettings: _emberData.default.belongsTo('company-setting', {
      async: true,
      inverse: 'company'
    })
  });

  _exports.default = _default;
});