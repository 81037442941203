define("cb-ui/router", ["exports", "cb-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  !function (t, e) {
    var o, n, p, r;
    e.__SV || (window.posthog = e, e._i = [], e.init = function (i, s, a) {
      function g(t, e) {
        var o = e.split(".");
        2 == o.length && (t = t[o[0]], e = o[1]), t[e] = function () {
          t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
        };
      }

      (p = t.createElement("script")).type = "text/javascript", p.async = !0, p.src = s.api_host + "/static/array.js", (r = t.getElementsByTagName("script")[0]).parentNode.insertBefore(p, r);
      var u = e;

      for (void 0 !== a ? u = e[a] = [] : a = "posthog", u.people = u.people || [], u.toString = function (t) {
        var e = "posthog";
        return "posthog" !== a && (e += "." + a), t || (e += " (stub)"), e;
      }, u.people.toString = function () {
        return u.toString(1) + ".people (stub)";
      }, o = "capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys".split(" "), n = 0; n < o.length; n++) {
        g(u, o[n]);
      }

      e._i.push([i, s, a]);
    }, e.__SV = 1);
  }(document, window.posthog || []);
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    metrics: Ember.inject.service(),
    sessionContext: Ember.inject.service(),
    didTransition: function didTransition() {
      this._super.apply(this, arguments);

      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var title = _this.getWithDefault('currentRouteName', 'unknown'); // const loc = document.location;
        // const page = loc.hash ? loc.hash.substring(1) : (loc.pathname + loc.search);


        var page = title;

        var user = _this.get('sessionContext.currentUser');

        if (user.get('trackingConsent')) {
          Ember.get(_this, 'metrics').trackPage({
            page: page,
            title: title
          });

          if (!window.posthog || !window.posthog.__loaded) {
            if (!window.location.href.includes('localhost') && !window.location.href.includes('demo')) {
              window.posthog.init('phc_rmJMJH55AlG7LSw2E1tV5xUvEIYaDokyFHkmE70b2tk', {
                api_host: 'https://eu.posthog.com'
              });
              window.posthog.identify(user.get('id').toString(), // distinct_id, required
              {
                user_email: user.get('email')
              } // $set, optional
              );
            }
          }
        }
      });
    },
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('index', {
      path: '/'
    });
    this.route('landing');
    this.route('wizard-language', {
      path: "new"
    });
    this.route('logout');
    this.route('wizard', {
      path: "/wizard/:quote_uuid",
      resetNamespace: true
    }, function () {
      this.route('index', {
        path: '/project_information'
      });
      this.route('consultants'); //this.route('project-details', {path: '/project_details'});

      this.route('confirm');
    });
    this.route('wizard');
    this.route('signup');
    this.route('login');
    this.route('recover-password', {
      path: '/recover_password'
    });
    this.route('new-password', {
      path: '/new_password'
    });
    this.route('app', function () {
      // this.route('consultants');
      this.route('rfp', {
        path: "/rfp/:quote_id"
      }, function () {
        this.route('index', {
          path: '/project_information'
        });
        this.route('consultants');
        this.route('project-details', {
          path: '/project_details'
        });
        this.route('criteria-evaluation', {
          path: '/criteria_evaluation'
        });
        this.route('confirm');
        this.route('visual-editor', {
          path: '/cover_letter'
        });
        this.route('custom-cover-letter', {
          path: '/custom_cover_letter'
        });
        this.route('cover-letter-choices', {
          path: '/cover_letter_choices'
        }); // this.route('management'); // set permissions for quote
      });
      this.route('project', {
        path: "/project/:quote_id"
      }, function () {
        this.route('client', function () {
          this.route('review', {
            path: "/project_review"
          });
          this.route('questions');
          this.route('proposals');
          this.route('technical', {
            path: '/technical_evaluation'
          });
          this.route('financial', {
            path: '/financial_evaluation'
          });
          this.route('pitch', {
            path: '/pitch_evaluation'
          });
          this.route('evaluation'); //this.route('beauty-contest', {path: "/beauty_contest"}); //  Beauty contest page

          this.route('configuration');
          this.route('project-management');
          this.route('announcements');
          this.route('timeline-configuration');
        });
        this.route('proposal', {
          path: "/proposal/:proposal_id"
        }, function () {
          this.route('summary'); // this.route('criteria-evaluation', {path: '/criteria_evaluation'});

          this.route('financial-quotation', {
            path: '/proposal'
          }); //this.route('technical-proposal', {path:'/technical_proposal'});

          this.route('questions');
          this.route('beauty-contest', {
            path: '/beauty_contest'
          });
          this.route('result');
          this.route('user-management');
          this.route('announcements');
        });
      });
      this.route('agent-project', {
        path: "agent_project/:quote_id"
      });
      this.route('account', function () {
        this.route('index', {
          path: 'profile'
        });
        this.route('change-password', {
          path: 'change_password'
        });
        this.route('confirm-account', {
          path: 'confirm_account'
        });
        this.route('cancel-account', {
          path: 'cancel_account'
        });
      });
      this.route('dashboard', function () {
        this.route('client');
        this.route('consultant');
        this.route('agent', {
          path: 'agent'
        });
      });
      this.route('templates', function () {
        this.route('index', {
          path: "/"
        });
        this.route('edit', {
          path: "/:template_id"
        });
      });
      this.route('management', function () {
        this.route('index', {
          path: "/"
        });
        this.route('quotes', {
          path: "/:user_id/quotes"
        });
      });
      this.route('org-settings', function () {
        this.route('index', {
          path: "/"
        });
        this.route('worker-roles', {
          path: "/worker_roles"
        }, function () {
          this.route('index', {
            path: "/"
          });
          this.route('edit', {
            path: "/:organization_worker_role_id/edit"
          });
        });
        this.route('cover-letter-settings', {
          path: "/cover_letter_settings"
        }, function () {
          this.route('index', {
            path: "/"
          });
          this.route('edit');
        });
      });
      this.route('process', {
        path: '/process/:quote_id'
      }, function () {});
      this.route('managementConsultant', function () {
        this.route('index', {
          path: "/"
        });
        this.route('proposals', {
          path: "/:user_id/proposals"
        });
      });
      this.route('notifications');
    });
    this.route('external-evaluation', {
      'path': 'external_evaluation'
    });
    this.route('news');
    this.route('axa-request-form');
    this.route('project-request-form');
    this.route('request-form');
    this.route('axa-request-success');
    this.route('project-request-success');
    this.route('request-success');
    this.route('ie-detected');
  });
  var _default = Router;
  _exports.default = _default;
});